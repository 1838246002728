import React from 'react';
import "./css/Products.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Button } from 'react-bootstrap';
import './css/HomeCarousel.css';

import FeedthroughProductImg1 from './assets/Feedthrough Product 1.png';
import HeaterProductImg1 from './assets/Heater Product 7.jpg';
import CustomVaccum2 from './assets/CustomVaccum12.jpg';

const Mailto = ({ email, subject, body, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
    );
  };

class Products extends React.Component {
    render() {
        window.scrollTo(0,0);
        return (
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Products & Services</Breadcrumb.Item>
                </Breadcrumb>
                <hr />
                <Row>
                <Col>
                <div align="center"><Image src={FeedthroughProductImg1} rounded className="mb-3 mt-3 shadow p-2 mb-4 bg-white rounded displayImage" width={600}/></div> <br />
                </Col>
                <Col>
                <br /><h4>Custom Hermetic Feedthroughs</h4><br />
                <ul>
                    <li>Feedthroughs tested for over 1000 hours at 1E-5 torr. Suitable for vacuum chambers up to 1E-6 torr. </li>
                    <li>Maximum 3 week lead time as opposed to minimum 4-6 weeks that is current industry standard. </li>
                    <li>100% customization – including connectors, wires and application. </li>
                    <li>MADE IN USA</li>
                </ul>
                <br />
                JPHB will apply no additional charges for your custom feedthrough and heating element needs. For us, every customer is our top priority, and we treat every order as an expedited order. 
                <br /><br />
                <b>For most orders, we guarantee delivery of your needs within 3 weeks.</b>
                <br /><br />
                <div align="center"><Button href='/#/Feedthrough' variant="secondary" size="md" className="m-3">Learn More</Button></div>
                </Col>
            </Row>
            <hr />
            <br />
            <Row>
                <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
                <br /><h4>Custom Heating Elements</h4><br />
                <ul>
                    <li>Suitable for use in vacuum, air or controlled ambient. </li>
                    <li>Tested for temperatures up to 1200°C. </li>
                    <li>Porcelain , alumina, zirconia and other silica-based heater packaging options available.</li>
                    <li>MADE IN USA </li>
                </ul>
                <br />
                JPHB will apply no additional charges for your custom feedthrough and heating element needs. For us, every customer is our top priority, and we treat every order as an expedited order. 
                <br /><br />
                <b>For most orders, we guarantee delivery of your needs within 3 weeks.</b>
                <br /><br />
                    <div align="center"><Button href='/#/Heater' variant="secondary" size="md" className="m-3">Learn More</Button></div>
                </Col>
                <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
                <div align="center"><Image src={HeaterProductImg1} rounded className="mb-3 mt-3 shadow p-2 mb-4 bg-white rounded displayImage" width={600}/></div> <br />
                </Col>
            </Row>
            <hr />
            <br />
            <Row>
                <Col>
                <div align="center"><Image src={CustomVaccum2} rounded className="mb-3 mt-3 shadow p-2 mb-4 bg-white rounded displayImage"/></div> <br />
                </Col>
                <Col>
                <br /><h4>Custom Thin Film Processing Equipment</h4><br />
                <ul>
                    <li>We understand the challenge that haunt academic and early state startup research and development at the cross-roads of cost and time. </li>
                    <li>Give us your budget and your project needs; we will make it work to meet your target performance. </li>
                    <li>Fabrication system development and deployment for thin film processing at low cost and market lowest lead times so your researchers can focus on science.  </li>
                    <li>MADE IN USA</li>
                </ul>
                <br /><br />
                <div align="center"><Button href='/#/ThinFilmProcessing' variant="secondary" size="md" className="m-3">Learn More</Button></div>
                </Col>
            </Row>
            <br></br>
            </Container>
        );
    }
}

export default Products
import React from 'react';
import { Layout } from './components/Layout';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Home  from './Home';
import Navajo from './Navajo';
import Feedthrough from './Feedthrough';
import Products from './Products';
import Investors  from './Investors';
import About  from './About';
import Contact  from './Contact';
import Compadv from './Compadv';
import { NoMatch } from './NoMatch';
import  NavigationBar  from './components/NavigationBar';
import { Footer } from './components/Footer';
import Heater from './Heater';
import ThinFilmProcessing from './ThinFilmProcessing';

function App() { 
  return (
    <React.Fragment>
      <Router>
      <NavigationBar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/Navajo" component={Navajo} />
            <Route exact path="/Feedthrough" component={Feedthrough} />
            <Route exact path="/Heater" component={Heater} />
            <Route exact path="/ThinFilmProcessing" component={ThinFilmProcessing} />
            <Route exact path="/Products" component={Products} />
            <Route exact path="/Investors" component={Investors} />
            <Route exact path="/About" component={About} />
            <Route exact path="/Contact" component={Contact} />
            <Route exact path="/Compadv" component={Compadv} />
            <Layout>
            <Route component={NoMatch} />
            </Layout>
          </Switch>
      <Footer />
      </Router>
    </React.Fragment>
  );
}

export default App;

import React from 'react';
import "./css/Feedthrough.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Button } from 'react-bootstrap';
import './css/HomeCarousel.css';

import FeedthroughImg1 from './assets/Feedthrough Product 1.png';
import FeedthroughImg2 from './assets/Feedthrough Product 2.png';
import FeedthroughImg3 from './assets/Feedthrough Product 3.png';
import FeedthroughImg4 from './assets/Feedthrough Product 4.png';
import FeedthroughImg5 from './assets/Feedthrough Product 1a.png.jpg';

class Feedthrough extends React.Component {
    render() {
        window.scrollTo(0,0);
        return (
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/#/Products">Products</Breadcrumb.Item>
                    <Breadcrumb.Item active>Feedthrough</Breadcrumb.Item>
                </Breadcrumb>
                <hr />
                    
                <Row>
                <Col>
                    <Image src={FeedthroughImg1} rounded width={500} className="float-left mt-4 mr-5 mb-4 shadow p-2 mb-4 bg-white rounded displayImage" /> <br />
                <br />
                </Col>
                    <Col>
                        <hr />
                        <h2 >Custom Hermetic Feedthrough</h2>
                        <hr />
                        <ul>
                            <li>Feedthroughs tested for over 1000 hours at 1E-5 torr. Suitable for vacuum chambers up to 1E-6 torr.</li>
                            <li><b>Maximum 3 week lead time</b> as opposed to minimum 4-6 weeks that is current industry standard. </li>
                            <li>100% customization – including connectors, wires and application. </li>
                            <li>MADE IN USA</li>
                        </ul>
                        <br />
                        <div className="d-grid gap-2 text-center"><Button href="mailto:amit@jphb.us?subject=Inquery on Custom Hermetic Feedthrough&cc=willnichols@jphb.us;" color="secondary" size="md">Contact us for a quote.</Button></div>
                        <br />
                        <i>For any questions or inqueries about what JPHB can provide for you, please reach out to <a href="mailto:amit@jphb.us?subject=Inquery on Feedback Connector&cc=willnichols@jphb.us.;">amit@jphb.us</a></i>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <hr />
                        <h3 >Product Standards and Mission</h3>
                        <hr />
                        JPHB is founded by enthusiastic researchers from academia who understand the two greatest challenges
                        academics in semiconductor and thin film research face – cost of instrumentation and lead-time to delivery.
                        <br /><br />
                        When mostly supported by federal grants, the industry standard of waiting 6-12 weeks or more for basic
                        necessities such as feedthroughs and heating elements is highly unfavorable. Moreover, any customization
                        typically takes several weeks and most commercial companies rarely prioritize academic customers...
                        <br /><br />
                        <h4>This is why JPHB has a garunteed lead time of 3 weeks maximum.</h4>
                        <br /><br />
                        We don’t have extra charges for expedited service, we treat every order as expedited!
                        <br /><br />
                        Our products include:
                        <ul>
                            <li>Custom hermetic feedthroughs for thermocouples, power supplies, and more. </li>
                            <li>Custom thin film growth reactors for sublimation, sputter deposition, thermal evaporation, vapor-transport and metalorganic chemical vapor deposition.</li>
                        </ul>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <hr />
                        <h3 >Custom Hermetic Feedthrough in action</h3>
                        <hr />
                        </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                    <Image src={FeedthroughImg5} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageFeedthrough" /> <br />
                    </Col>
                    <Col className="text-center">
                    <Image src={FeedthroughImg2} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageFeedthrough" /> <br />
                    <Image src={FeedthroughImg3} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageFeedthrough" /> <br />
                    </Col>
                </Row>
                <br></br>
            </Container>
        );
    }
}

export default Feedthrough
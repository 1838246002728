import React from 'react';
import "./css/Heater.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Button } from 'react-bootstrap';
import './css/HomeCarousel.css';

import HeaterImg1 from './assets/Heater Product 7.jpg';
import HeaterImg2 from './assets/Heater Product 1.jpg';
import HeaterImg3 from './assets/Heater Product 2.jpg';
import HeaterImg4 from './assets/Heater Product 3.png';
import HeaterImg5 from './assets/Heater Product 4.png';
import HeaterImg6 from './assets/Heater Product 5.png';
import HeaterImg7 from './assets/Heater Product 6.png.jpg';
import HeaterImg8 from './assets/Heater Product 8.jpg';

class Heater extends React.Component {
    render() {
        window.scrollTo(0,0);
        return (
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/#/Products">Products</Breadcrumb.Item>
                    <Breadcrumb.Item active>Heating</Breadcrumb.Item>
                </Breadcrumb>
                <hr />
                    
                <Row>
                <Col>
                    <Image src={HeaterImg1} rounded width={500} className="float-left mt-4 mr-5 mb-4 shadow p-2 mb-4 bg-white rounded displayImage" /> <br />
                <br />
                </Col>
                    <Col>
                        <hr />
                        <h2 >Custom Heating Elements</h2>
                        <hr />
                        <ul>
                            <li>Suitable for use in vacuum, air or controlled ambient.</li>
                            <li>Tested for temperatures up to 1200°C.</li>
                            <li>Porcelain , alumina, zirconia and other silica-based heater packaging options available. </li> 
                            <li>MADE IN USA</li>

                        </ul>
                        <br />
                        <div className="d-grid gap-2 text-center"><Button href="mailto:amit@jphb.us?subject=Inquery on Custom Heatin Elements&cc=willnichols@jphb.us;" color="secondary" size="md">Contact us for a quote.</Button></div>
                        <br />
                        <i>For any questions or inqueries about what JPHB can provide for you, please reach out to <a href="mailto:amit@jphb.us?subject=Inquery on Feedback Connector&cc=willnichols@jphb.us.;">amit@jphb.us</a></i>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <hr />
                        <h3 >Product Standards and Mission</h3>
                        <hr />
                        JPHB is founded by enthusiastic researchers from academia who understand the two greatest challenges
                        academics in semiconductor and thin film research face – cost of instrumentation and lead-time to delivery.
                        <br /><br />
                        When mostly supported by federal grants, the industry standard of waiting 6-12 weeks or more for basic
                        necessities such as feedthroughs and heating elements is highly unfavorable. Moreover, any customization
                        typically takes several weeks and most commercial companies rarely prioritize academic customers...
                        <br /><br />
                        <h4>This is why JPHB has a garunteed lead time of 3 weeks maximum.</h4>
                        <br /><br />
                        We don’t have extra charges for expedited service, we treat every order as expedited!
                        <br /><br />
                        Our products include:
                        <ul>
                            <li>Custom fabricated heaters and crucibles to give you the thermal uniformity that your research demands.</li>
                            <li>Graphite crucibles, precision machined to meet the application needs. </li>
                            <li>Need a CFD based design review prior to ordering your heaters or crucibles? Talk to our design experts. </li>
                        </ul>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <hr />
                        <h3 >Custom Heating Elements In Action</h3>
                        <hr />
                        </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                    <Image src={HeaterImg8} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageHeater" /> <br />
                    </Col>
                    <Col className="text-center">
                    <Image src={HeaterImg2} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageHeater" /> <br />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                    <Image src={HeaterImg3} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageHeater" /> <br />
                    </Col>
                    <Col className="text-center">
                    <Image src={HeaterImg4} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageHeater" /> <br />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                    <Image src={HeaterImg5} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageHeater" /> <br />
                    </Col>
                    <Col className="text-center">
                    <Image src={HeaterImg6} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageHeater" /> <br />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                    <Image src={HeaterImg7} rounded className="shadow p-2 mb-4 bg-white rounded" /> <br />
                    </Col>
                </Row>
                <br></br>
            </Container>
        );
    }
}

export default Heater
import React from 'react';
import "./css/Investors.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap';

import InvRes from './assets/InvRes.jpg';
import InvestorImg1 from './assets/Investors1.png';
import InvestorImg2 from './assets/Investors2.png';
import InvestorImg3 from './assets/Investors3.jpg';
import InvestorImg4 from './assets/Investors4.jpg';

const Mailto = ({ email, subject, body, children }) => {
    return (
        <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
    );
};

function Investors() {
    window.scrollTo(0, 0);
    return (
        <React.Fragment>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Investor Resources</Breadcrumb.Item>
                </Breadcrumb>
                <hr />
                <Row>
                    <Col>
                        <div className="GreyRounded">
                            Interested in a detailed <span style={{ fontWeight: 500 }}>pitchdeck</span> from JPHB? Please contact <Mailto email="amit@jphb.us" subject="Investor Pitchdeck Request" body="" style="color: blue;"><span style={{ color: 'blue' }}>amit@jphb.us</span></Mailto> to request more information.
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <h2 style={{ paddingBottom: '10px' }}><span className="underline underlineBlue">Investor Resources</span></h2>
                        JPHB team has several years of experience and expertise in thin-film solar energy technologies, and computational materials for current and next generation photovoltaics solutions. Our portfolio contains a wide spectrum of thin film solar solutions including –
                        <br /><br />
                        <ul>
                            <li>Semi-conductor thin film deposition and processing</li>
                            <li>Transparent and metallic thin films for photovoltaic applications</li>
                            <li>Process development and material discovery for next generation PV</li>
                            <li>Identification of performance limiting factors and mitigation in thin film solar manufacturing</li>
                            <li>Computational materials research using first principle atomistic modeling</li>
                        </ul>
                        <br />
                        <div>
                            JPHB team specializes in Cadmium Telluride based PV technology (CdTe) which is highly suitable for large scale gigawatt scale manufacturing. We have demonstrated some of the highest efficiencies for CdTe solar cells using scalable methods. Furthermore, we have made module architectural modifications which can nearly double to power output of the panels with modest increase in manufacturing cost. The figure below shows results from one day of power generation using 24” x 48” commercial size solar panels.
                        </div>
                        <img src={InvestorImg1} className="InvestorImg max-width-800-px" alt="about_us" />
                        <div>
                            CdTe is highly suitable technology for utility scale energy generation and for identical installation capacity, it can produce up to 20% greater power output. This is due the favorable physical and optical characteristics of the material that allows it to efficiently produce energy even under low light and moderate cloudy conditions. This can be seen in our test installation results in directed comparison to identical installed capacity of front-line silicon solar panels.
                        </div>
                        <img src={InvestorImg2} className="InvestorImg" alt="about_us" />
                        <div>
                            Furthermore, JPHB is working with 100% United States/North America based supply chain for development of solar panels and next generation solar technologies. This is critical to maintain the US energy security as well as to ensure businesses are not affected by oversees/intercontinental supply chain issues. Our team has a strong collaboration with US solar industry and academic research groups which means new advances can be promptly implemented for commercial applications. The picture below is of a proof of concept prototype solar sub-module fabricated by JPHB team.
                        </div>
                        <img src={InvestorImg3} className="max-width-400-px InvestorImg" alt="about_us" />
                        <div>
                            JPHB is also owns an in-line semiconductor thin-film submodule manufacturing unit along with sputter and laser-based R&D infrastructure. This provides a rich amalgamation of technology development and demonstration of scalability. We, at JPHB, firmly believe that inventions become great only when they leave the laboratory and reach the market for the society to enjoy the benefits. The ultimate test of technology is the market, and our is confident of being the answer to the challenge.
                        </div>
                        <img src={InvestorImg4} className="max-width-600-px InvestorImg" alt="about_us" />
                        <div>
                            We understand the complete process from technology development to proof-of-concept, to prototyping, leading into large scale production. Our leadership and advisory board have a combined technology and business experience of over 50 years. Together, with new partnerships, we can develop an infrastructure to meet energy challenges of the United States and the world.
                            <br /><br />
                            JPHB is committed to development of next generation two and three junction polycrystalline solar devices. These are inspired from demonstrated space solar technologies which have achieved efficiencies exceeding 40% as compared to current terrestrial technologies which are about 20% efficient. The space solar technologies with high efficiency are cost prohibitive due to expensive fabrication methods and materials. The cost of these panels run into several hundred to several thousand dollars per watt while the terrestrial technologies must be less than $1/watt to be cost competitive. Our target is development of multi-junction solar panel technologies that are over 30% efficient while maintaining $1/watt price tag.
                            <br /><br />
                            JPHB is now a proud member of the of U.S. Manufacturing of Advanced Cadmium Telluride Photovoltaics (US-MAC) Consortium - industry, national labs, and universities dedicated to strengthening U.S. leadership in manufacturing cadmium telluride (CdTe).
                            <br /><br />
                            For more information please contact us –<br />
                            <Mailto email="info@jphb.us" subject="" body=""><span style={{ color: 'blue' }}>info@jphb.us</span></Mailto><br />
                            Phone: <a href="tel:970-682-9338"><span style={{ color: 'blue' }}>+1 (970) 682-9338</span></a><br />
                            You can also request a pitch deck or personal meeting with the leadership team through text or email.<br />
                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col>
                    <h2 style={{paddingBottom: '10px'}}><span className="underline underlineBlue">Timeline</span></h2>
                    JPHB is a company created by professionals with a vision to take disruptive innovations
                    from the laboratory to society. These innovations are for commercial installations and PPA using currently available solar panels, and replacing them with in-house manufactured high-efficiency solar panels in the near future.
                    </Col>
                </Row>
                <Row>
                <Col>
                    <Timeline lineColor={'#2d419a'}>
                        <TimelineItem
                            key="001"
                            dateText="Milestone 1"
                            style={{ color: '#f9a11c' }}
                            dateInnerStyle={{ background: '#2d419a', color: 'white' }}
                        >
                            <h3>Entrypoint Evaluation</h3>
                            <br />
                            <p>
                            <ul>
                                <li>Lowest cost of energy generation with demonstrated power output advantage. </li>
                                <li>Power purchase agreements (PPA) utilizing existing real estate with small to medium businesses. </li>
                                <li>Community scale PV installations.</li>
                            </ul>
                            </p>
                        </TimelineItem>
                        <TimelineItem
                            key="002"
                            dateText="Milestone 2"
                            style={{ color: '#f9a11c' }}
                            dateInnerStyle={{ background: '#2d419a', color: 'white' }}
                        >
                            <h3>Revenue Streams</h3>
                            <br />
                            <p>
                            <ul>
                                <li>Small to medium scale PPA eventually leading into MW-scale PPA with utilities. </li>
                                <li>Shortest Return on Investment (ROI) projections – reliable long term revenue stream </li>
                            </ul>
                            </p>
                        </TimelineItem>
                        <TimelineItem
                            key="003"
                            dateText="Milestone 3"
                            style={{ color: '#f9a11c' }}
                            dateInnerStyle={{ background: '#2d419a', color: 'white' }}
                        >
                            <h3>IP Generation</h3>
                            <br />
                            <p>
                            <ul>
                                <li>Technologies patented and exclusive option to JPHB.</li>
                                <li>Disruptive innovation in module installation method with unprecedented power generation advantage. </li>
                                <li>Key improvements in device architecture and manufacturing methodology. </li>
                                <li>Reduced time of manufacturing, complexity, and process steps for output comparable to the current state-of-the-art.</li>
                                <li>Dopant free devices for simpler process control, longer filed longevity. </li>
                            </ul>
                            </p>
                        </TimelineItem>
                        <TimelineItem
                            key="004"
                            dateText="Milestone 4"
                            style={{ color: '#f9a11c' }}
                            dateInnerStyle={{ background: '#2d419a', color: 'white' }}
                        >
                            <h3>Applications</h3>
                            <br />
                            <p>
                            <ul>
                                <li>Rooftop and utility scale installations for PPA.</li>
                                <li>Ultra-thin substrate high power to weight ratio panels customized for space, military, and disaster relief. </li>
                                <li>Cutting-edge module architecture to improve power output by over 2x with marginal increase in manufacturing cost for field installations. </li>
                            </ul>
                            </p>
                        </TimelineItem>
                        <TimelineItem
                            key="005"
                            dateText="Milestone 5"
                            style={{ color: '#f9a11c' }}
                            dateInnerStyle={{ background: '#2d419a', color: 'white' }}
                        >
                            <h3>Peak Functionality</h3>
                            <br />
                            <p>
                            <ul>
                                <li>A two-prong approach of PPA and manufacturing for long-term self-sustained for-profit model.</li>
                                <li>PPAs provide reliable, ongoing revenue stream. </li>
                                <li>Module development and manufacturing to feed the PPA model with high-quality low-cost panels.</li>
                                <li>Light weight high power-weight ratio panels manufacturing to further improve the commercial advantage through specialty applications. </li>
                            </ul>
                            </p>
                        </TimelineItem>
                        <TimelineItem
                            key="005"
                            dateText="Long Term Projections"
                            style={{ color: '#f9a11c' }}
                            dateInnerStyle={{ background: '#2d419a', color: 'white' }}
                        >
                            <h3>Enter Sustainable Food-Energy-Water Nexus</h3>
                        </TimelineItem>
                        </Timeline>
                    </Col>
                </Row> */}
            </Container>
            <br />
            <br />
        </React.Fragment>
    );
}

export default Investors;
import React from 'react';
import "./css/About.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';

import amiths from './assets/amiths2.png';
import kevanhs from './assets/kevan.png';
import anthonyhs from './assets/anthonyhs.jpg';
import willhs from './assets/willhs.jpeg';
import jayhs from './assets/jayhs.png';
import sampathhs from './assets/sampathhs.png';
import tfdLogo from './assets/TFD.png';
import mdgreenenergy from './assets/mdgreenenergypartner.png';
import slide01 from './assets/sunrisenog.jpg';
import blakehs from './assets/blakehs.jfif';
import carinahs from './assets/carinahs.jpg';
import doughs from './assets/doug.jpg';

class About extends React.Component {
    state = { showingAmit: false, showingAnthony: false, showingAkash: false, showingZach: false, showingWill: false, showingJay: false, showingSampath: false };
    render() {
        const { showingAmit, showingAnthony, showingAkash, showingZach, showingWill, showingJay, showingSampath } = this.state;
        return (
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>About Us</Breadcrumb.Item>
                </Breadcrumb>
                <hr />
                <Row>
                    <Col>
                        <div class="about-image-block-container right-overlap">
                            <img src={slide01} className="about-content-img" alt="about_us" />
                            <div className="about-outer">
                                <div class="about-text-block">
                                    <h2>Our Vision</h2>
                                    <hr className="visionHr" /><br />
                                    At <b>JPHB</b>, we believe that solutions to the biggest and the most complex problems must be simple. Only a simple solution can be executed at a scale that can make an impact for the betterment of the human society and world.
                                    <br /><br />
                                    Our vision is to identify and implement disruptive yet simple engineering innovations that address some of the most challenging energy problems, thus making them sustainable, economically viable, and provide the U.S. energy industry a substantial competitive advantage.
                                    <br /><br /><sub>Learn more about how we live out this vision <a href="/#/Investors" style={{ color: "blue" }}>here.</a></sub>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <br /><br />
                <Row className="rowAbout">
                    <Col className="visionContainer" sm="12" md={{ size: 6 }}>
                        <br />
                        <div>
                            <h2>Our Team</h2>
                            <hr className="visionHr" />
                        </div>
                    </Col>
                </Row>
                <Row className="rowAbout">
                    <Col xs={12} sm={{ span: 6 }}>
                        <Card>
                            <CardImg top src={amiths} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                                <CardTitle tag="h5">Amit Munshi</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">President and CEO</CardSubtitle>
                                <hr />
                                <CardText>
                                    Dr. Amit Haren Munshi has pursued state-of-the-art solar energy and materials engineering research for over 10 years and has been one of the most successful researchers in thin film photovoltaics. A recipient of several international academic awards, Dr. Munshi has been key to some of the key innovations in his area of expertise. Dr. Munshi has led research scale solar power conversion efficiency of CdTe thin film photovoltaics from 11% to over 20% over his academic research career. He and his team of motivated professionals have been key to introduction of several new processes and materials families to solar PV research. With a successful academic career, Dr. Munshi has now embarked on an entrepreneurial journey to take cutting-edge solutions from academic laboratory to commercial space. His vision – To make solar energy the obvious choice through technological, financial, functional, and entrepreneurial innovation.
                                    <span style={{ display: (showingAmit ? 'block' : 'none') }}>
                                        <br />It is Dr. Munshi’s firm conviction that any invention or innovation, no matter how great, has little value unless it makes its way out of the laboratories and into the real world. Following this philosophy, Dr. Munshi founded JPHB to apply his knowledge and experience to solve problems that matter. At the same time, Dr. Munshi understands that economic viability of energy solutions is critical since the problem of this magnitude must be self-sustained for deployment and growth.
                                    </span>
                                    <a href="javascript:void(0);" onClick={() => this.setState({ showingAmit: !showingAmit })} style={{ display: (showingAmit ? 'none' : 'block'), color: "#2d419a", paddingTop: "10px" }}>Read More...</a>
                                    <a href="javascript:void(0);" onClick={() => this.setState({ showingAmit: !showingAmit })} style={{ display: (showingAmit ? 'block' : 'none'), color: "#2d419a", paddingTop: "10px" }}>Hide...</a>
                                </CardText>
                                <a href="https://scholar.google.com/citations?user=aQ2ulaYAAAAJ" style={{ color: "#2d419a" }}>Publications &gt;</a><br />
                                <a href="https://www.linkedin.com/in/amit-munshi/" style={{ color: "#2d419a" }} target="_blank">Linkedin &gt;</a>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} sm={{ span: 6 }}>
                        <Card>
                            <CardImg top src={kevanhs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                                <CardTitle tag="h5">Kevan Cameron</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">Chief Engineer</CardSubtitle>
                                <hr />
                                <CardText>
                                Kevan Cameron is an honorably discharged US Navy Veteran. Following his years in service as a submariner, Kevan has extensively contributed as vacuum systems and thin film processing engineer. The highlights of his prolific career includes Shift Manager at Lockheed Martin Space Simulations Laboratory, Operations Engineer at ITN Energy, Engineering Technical Specialist at Advanced Energy, Thin-Films Process and Production Engineer at Infinite Power Solutions, and Power Source Engineer at Precision Ion Tech. Prior to his retirement in 2021, Kevan worked at the Colorado State University Next Generation PV Center leading equipment and process engineering as a senior Research Associate. During his time at CSU,  Kevan worked closely with the CEO and played a crucial role as his mentor for vacuum system technologies that laid a strong foundation for creation of this company. At JPHB, Kevan has joined hands as the Chief Engineer and will play a central role in plant management as well as process and quality control.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="rowAbout">
                </Row>
                <Row className="rowAbout">
                <Col xs={12} sm={{ span: 6 }}>
                        <Card>
                            <CardImg top src={willhs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                                <CardTitle tag="h5">Will Nichols</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">Operations Lead</CardSubtitle>
                                <hr />
                                <CardText>
                                    Will has a dual bachelor’s degree in both Computer Science and Finance. He brings a wide experience background including business IT solutions, web design, and financial analysis. This background provides a wide encompassing mindset to bring crucial software solutions to JPHB ranging from engineering support to business management and financial and analysis.
                                    {/* <span style={{ display: (showingWill ? 'block' : 'none') }}> */}
                                        <br /><br />
                                        Will's experience and passion stems from entrepreneurs throughout his family and through his own pursuits of in other realms such as facility management and personal training. Throughout his academic career he pursed many different passions and positions including IT management, web design, facility management, and even personal training, which gave him both the personal skills to build client relationships as well as opportunities to problem solve and engineer IT solutions in a business environment.
                                    {/* </span> */}
                                    {/* <a href="javascript:void(0);" onClick={() => this.setState({ showingWill: !showingWill })} style={{ display: (showingWill ? 'none' : 'block'), color: "#2d419a", paddingTop: "10px" }}>Read More...</a>
                                    <a href="javascript:void(0);" onClick={() => this.setState({ showingWill: !showingWill })} style={{ display: (showingWill ? 'block' : 'none'), color: "#2d419a", paddingTop: "10px" }}>Hide...</a> */}
                                </CardText>
                                <a href="https://www.linkedin.com/in/william-nichols-699b50157/" style={{ color: "#2d419a" }} target="_blank">Linkedin &gt;</a>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} sm={{ span: 6 }}>
                        <Card>
                            <CardImg top src={anthonyhs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                                <CardTitle tag="h5">Anthony Nicholson</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">Lead Scientist</CardSubtitle>
                                <hr />
                                <CardText>
                                    Dr. Anthony Pino Nicholson earned his doctorate in Mechanical Engineering from Colorado State University with a focus on computational-based materials research for thin-film photovoltaics and manufacturing processes. His area of expertise spans a wide spectrum including Computational Fluid Dynamics, Finite Element Methods, Density Functional Theory, and Numerical Device Simulations.
                                    <span style={{ display: (showingAnthony ? 'block' : 'none'), whiteSpace: 'no-wrap' }}>
                                        <br />
                                        He has demonstrated a strong basis in the subject matter related to photovoltaics with an emphasis toward improving the fundamental understanding of the technology. Dr. Nicholson is currently serving as a postdoctoral fellow at the National Renewable Energy Laboratory and aspires to expand the scope as well as impact of his research. Through his fellowship position and association with JPHB, Dr. Nicholson aspires to make a strong impact in the synergistic intersection of solar technology, education, social empowerment, and sustainable energy development.
                                        <br /><br />
                                        Dr. Nicholson is an enrolled member of the Diné, or the Navajo Nation. He seeks to empower Native American/Indigenous communities across the country starting with the Navajo Mountain (Naatsis'áán) and Alamo (T'iis Tsoh) Navajo Reservation regions. Dr. Nicholson has been instrumental in JPHB's ongoing project with Navajo Nation to assess the feasibility of off-grid systems for local indigenous families aimed at improving the standard of living in their communities. Vision and support provided by Dr. Nicholson and his family has laid the foundation for the JPHB team to make an effort towards providing these communities with a self-sustained and economical energy solution.
                                    </span>
                                    <a href="javascript:void(0);" onClick={() => this.setState({ showingAnthony: !showingAnthony })} style={{ display: (showingAnthony ? 'none' : 'block'), color: "#2d419a", paddingTop: "10px" }}>Read More...</a>
                                    <a href="javascript:void(0);" onClick={() => this.setState({ showingAnthony: !showingAnthony })} style={{ display: (showingAnthony ? 'block' : 'none'), color: "#2d419a", paddingTop: "10px" }}>Hide...</a>
                                </CardText>
                                <a href="https://www.linkedin.com/in/anthony-nicholson-3231921b/" style={{ color: "#2d419a" }} target="_blank">Linkedin &gt;</a>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* <Row className="rowAbout">
                <Col xs={12} sm={{ span: 6 }}>
                        <Card>
                            <CardImg top src={carinahs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                                <CardTitle tag="h5">Carina Morroni</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">Intern</CardSubtitle>
                                <hr />
                                <CardText>
                                Opto-electrical characterization of semiconductor materials and associated thin films, process development and solar cell fabrication.
                                <br /><br />
                                <i>Proud recipient of the prestigious CSU Scott Scholarship for Research Experience for Undergraduates.</i>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} sm={{ span: 6 }}>
                        <Card>
                            <CardImg top src={blakehs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                                <CardTitle tag="h5">Blake Hill</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">Intern</CardSubtitle>
                                <hr />
                                <CardText>
                                Electron Microscopy based materials characterization of semiconductors and associated thin films, process development and solar cell fabrication.
                                <br /><br />
                                <i>Honorably discharged US Army Veteran and current Accelerated Masters Program candidate at CSU.</i>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row> */}
                <Row className="rowAbout">
                    <Col className="visionContainer" sm="12" md={{ size: 6 }}>
                        <br />
                        <div>
                            <h2>Advisory Board
                            </h2>
                            <hr className="visionHr" />
                        </div>
                    </Col>
                </Row>
                <Row className="rowAbout">
                    <Col xs={12} sm={{ span: 6 }}>
                        <Card>
                            <CardImg top src={jayhs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                                <CardTitle tag="h5">Jay Munshi</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted"></CardSubtitle>
                                <hr />
                                <CardText>
                                    Jay has a demonstrated expertise in Data Science and Databases since mid-1990s and leads Database Management department for IPSoft Inc. He is a senior manager responsible for deliverables for fortune 1000 clients and his organizational team spans across North America, Europe, and South Asia. Jay along with his team uses Data Analysis tools to employ best practices in Technology Support to roll out Robotic Automation. Through his expertise, Jay has led a diverse team to deliver 99.99999% uptime while eliminating human error. Jay also serves as a member of the Big Data Certificate Program Advisory Board since 1998.
                                    <span style={{ display: (showingJay ? 'block' : 'none') }}>
                                        <br />
                                        At JPHP, Jay supports the team through his expert advice on data science and management, finance as well as market strategies. Biologically older brother of the founder Dr. Munshi and professionally his mentor and guide, Jay has been one the thrust behind the development of JPHB. He continues to guide the entrepreneurial effort and operational framework at JPHB.
                                    </span>
                                    <a href="javascript:void(0);" onClick={() => this.setState({ showingJay: !showingJay })} style={{ display: (showingJay ? 'none' : 'block'), color: "#2d419a", paddingTop: "10px" }}>Read More...</a>
                                    <a href="javascript:void(0);" onClick={() => this.setState({ showingJay: !showingJay })} style={{ display: (showingJay ? 'block' : 'none'), color: "#2d419a", paddingTop: "10px" }}>Hide...</a>
                                </CardText>
                                <a href="https://www.linkedin.com/in/jaymunshi/" style={{ color: "#2d419a" }} target="_blank">Linkedin &gt;</a>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} sm={{ span: 6 }}>
                        <Card>
                            <CardImg top src={sampathhs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                                <CardTitle tag="h5">Walajabad Sampath</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted"></CardSubtitle>
                                <hr />
                                <CardText>
                                    Prof. W.S. Sampath is a professor of Mechanical Engineering at Colorado State University and has been an expert in fields of thin film photovoltaics, materials engineering, semi-conductor materials science, and engineering applications of solid-state physics since 1985. He was also one of the founders of multi-million-dollar solar panel manufacturer Abound Solar. Prof. Sampath has been one of the leading researchers in thin film photovoltaics and is driven by his vision to make solar electricity a leading source of energy for the United States and the world. He has led pioneering efforts in establishment of CdTe-based photovoltaics as a commercially successful technology. He was one of the first researchers to demonstrate feasibility of CdTe-based photovoltaics for utility scale field application.
                                    <span style={{ display: (showingSampath ? 'block' : 'none') }}>
                                        <br />
                                        Prof. Sampath has been the academic advisor for the member of the founding team. At JPHB, Prof. Sampath has agreed to guide the technological developments for an ecologically and economically self-sustained solar energy solution network. His expertise and passion for sustainable energy is a critical foundational building block for the success of JPHB as an enterprise.
                                    </span>
                                    <a href="javascript:void(0);" onClick={() => this.setState({ showingSampath: !showingSampath })} style={{ display: (showingSampath ? 'none' : 'block'), color: "#2d419a", paddingTop: "10px" }}>Read More...</a>
                                    <a href="javascript:void(0);" onClick={() => this.setState({ showingSampath: !showingSampath })} style={{ display: (showingSampath ? 'block' : 'none'), color: "#2d419a", paddingTop: "10px" }}>Hide...</a>
                                </CardText>
                                <a href="https://www.engr.colostate.edu/me/faculty/dr-walajabad-s-sampath/" style={{ color: "#2d419a" }} target="_blank">CSU Profile &gt;</a>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="rowAbout">
                    <Col xs={12} sm={{ span: 6, offset: 3 }}>
                        <Card>
                            <CardImg top src={doughs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                                <CardTitle tag="h5">Doug Schatz</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted"></CardSubtitle>
                                <hr />
                                <CardText>
                                Doug Schatz is a popular name in the energy engineering industry. Doug founded the famous Advanced Energy in 1981 and served as the chairman of the company for nearly 33 years. Building on Dougs effort for decades, Advanced Energy's current market capitalization is over $3B. He has also served in leadership roles in numerous other companies like Solayer, Ampt LLC, and Vericatch. He has been instrumental in providing business development mentorship to the JPHB founders and continues to help in identifying unique opportunities. Doug's mentorship has been critical to the foundation of the company envisioned by the JPHB team. Alongside business mentorship, Doug also guides JPHB with technology assessment and roadmapping. 
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="rowAbout">
                    <Col className="visionContainer" sm="12" md={{ size: 6 }}>
                        <br />
                        <div>
                            <h2>Partners
                            </h2>
                            <hr className="visionHr" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={{ span: 4 }} style={{ textAlign: 'center' }}>
                        <br />
                        <img src={tfdLogo} style={{ display: 'inline-block' }} className="" alt="about_us" />
                    </Col>
                    <Col xs={12} sm={{ span: 8 }}>
                        TFD’s mission is to help solve Thin Film electro-optical issues and speed up transfer from pre-production to mass production either in the U.S. or Asia.
                        <br /><br />
                        <a style={{ color: 'blue' }} href="https://tfdinc.com/">https://tfdinc.com/</a>
                    </Col>
                </Row>
                <br /><hr /><br />
                <Row>
                    <Col xs={12} sm={{ span: 4 }} style={{ textAlign: 'center' }}>
                        <img src={mdgreenenergy} style={{ display: 'inline-block' }} className="" alt="about_us" />
                    </Col>
                    <Col xs={12} sm={{ span: 8 }}>
                        Turning environment sustenance into an investment in business growth with customer focus.
                        <br /><br />
                        A renewable energy and energy efficiency company that believes in bringing value to business through streamlining of processes, new technologies and a thorough understanding of market needs.
                        <br /><br />
                        <a style={{ color: 'blue' }} href="https://mdgreenenergy.com/index.html">https://mdgreenenergy.com</a>
                    </Col>
                </Row>
                <br /><br />
            </Container>
        );
    }
}

export default About;
import React from 'react';
import '.././css/Layout.css';
import { Container, Row, Col } from 'react-bootstrap';

export const Layout = (props) => (
    <div className="App">
        <div className="App-header">
        <Container>
                <Row>
                    <Col md={{ span: 12, offset: 0 }}>
                        {props.children}
                    </Col>
                </Row>
        </Container>
        </div>
    </div>
) 
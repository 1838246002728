import React from 'react';
import "./css/ThinFilmProcessing.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Button } from 'react-bootstrap';
import './css/HomeCarousel.css';

import ThinFilmImg1 from './assets/CustomVaccum12.jpg';
import ThinFilmImg2 from './assets/CustomVaccum1.jpg';
import ThinFilmImg3 from './assets/CustomVaccum2.jpg';
import ThinFilmImg4 from './assets/CustomVaccum3.jpg';
import ThinFilmImg5 from './assets/CustomVaccum4.png';
import ThinFilmImg6 from './assets/CustomVaccum5.png';
import ThinFilmImg7 from './assets/CustomVaccum6.png';
import ThinFilmImg8 from './assets/CustomVaccum7.jpg';
import ThinFilmImg9 from './assets/CustomVaccum8.jpg';
import ThinFilmImg10 from './assets/CustomVaccum9.jpg';
import ThinFilmImg11 from './assets/CustomVaccum10.jpg';
import ThinFilmImg12 from './assets/CustomVaccum11.jpg';
import ThinFilmImg13 from './assets/CustomVaccum12.jpg';
import ThinFilmImg14 from './assets/CustomVaccum13.jpg';


class ThinFilmProcessing extends React.Component {
    render() {
        window.scrollTo(0,0);
        return (
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/#/Products">Products</Breadcrumb.Item>
                    <Breadcrumb.Item active>Thin Film Processing</Breadcrumb.Item>
                </Breadcrumb>
                <hr />
                    
                <Row>
                <Col>
                    <Image src={ThinFilmImg1} rounded width={500} className="float-left mt-4 mr-5 mb-4 shadow p-2 mb-4 bg-white rounded displayImage" /> <br />
                <br />
                </Col>
                    <Col>
                        <hr />
                        <h2 >Custom Thin Film Processing Equipment</h2>
                        <hr />
                        <ul>
                            <li>We understand the challenge that haunt academic and early state startup research and development at the cross-roads of cost and time. </li>
                            <li>Give us your budget and your project needs; we will make it work to meet your target performance.</li>
                            <li>Fabrication system development and deployment for thin film processing at low cost and market lowest lead times so your researchers can focus on science.</li> 
                            <li>MADE IN USA</li>
                        </ul>
                        <br />
                        <div className="d-grid gap-2 text-center"><Button href="mailto:amit@jphb.us?subject=Inquery on Thin Film Processing Equipment&cc=willnichols@jphb.us;" color="secondary" size="md">Contact us for a quote.</Button></div>
                        <br />
                        <i>For any questions or inqueries about what JPHB can provide for you, please reach out to <a href="mailto:amit@jphb.us?subject=Inquery on Feedback Connector&cc=willnichols@jphb.us.;">amit@jphb.us</a></i>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <hr />
                        <h3>Is your vision for a dream laboratory out of sync with the available capital? </h3>
                        <hr />
                        <br />
                        Discuss your needs with our vacuum tech and thin film fabrication systems experts. We will work with your budget and your needs, to deliver your dream at the lowest budget and shortest lead-time. 
                        <br /><br />
                        </Col>
                </Row>
                <Row>
                    <Col>
                        <hr />
                        <h3 >Product Standards and Mission</h3>
                        <hr />
                        <ul>
                            <li>Our goal is to empower academic and early-stage startup R&D infrastructure through affordable and timely delivery of components.</li>
                            <li>Semiconductor, thin-film and vacuum processing technologies demand specialized components but the lead times are often excruciating and often unaffordable for academic and early stage small businesses.</li>
                            <li>We don’t have extra charges for expedited service, we treat every order as expedited!</li>
                            <li>Our products and services 
                                <ul>
                                    <li>From system design to process optimization, we have you covered.</li>
                                    <li>In-situ process monitoring, automation and user-friendly virtual interface, data logging and analytics, and more – to your satisfaction. </li>
                                </ul>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <hr />
                        <h3 >Custom Thin Film Processing Equipment In Action</h3>
                        <hr />
                        </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                    <Image src={ThinFilmImg2} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageCustom" /> <br />
                    </Col>
                    <Col className="text-center">
                    <Image src={ThinFilmImg4} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageCustom" /> <br />
                    </Col>
                    <Col className="text-center">
                    <Image src={ThinFilmImg7} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageCustom" /> <br />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                    <Image src={ThinFilmImg5} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageCustom" /> <br />
                    </Col>
                    <Col className="text-center">
                    <Image src={ThinFilmImg6} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageCustom" /> <br />
                    </Col>
                    <Col className="text-center">
                    <Image src={ThinFilmImg8} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageCustom" /> <br />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                    <Image src={ThinFilmImg12} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageCustom" /> <br />
                    </Col>
                    <Col className="text-center">
                    <Image src={ThinFilmImg10} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageCustom" /> <br />
                    </Col>
                    <Col className="text-center">
                    <Image src={ThinFilmImg11} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageCustom" /> <br />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                    <Image src={ThinFilmImg9} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageCustom" /> <br />
                    </Col>
                    <Col className="text-center">
                    <Image src={ThinFilmImg14} rounded className="shadow p-2 mb-4 bg-white rounded galleryImageCustom" /> <br />
                    </Col>
                </Row>
                <br></br>
            </Container>
        );
    }
}

export default ThinFilmProcessing
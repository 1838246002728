import React from 'react';
import "./css/Investors.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap';

function Contact() {
    window.scrollTo(0,0);
    const Mailto = ({ email, subject, body, children }) => {
        return (
          <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
        );
      };
    return (
        <React.Fragment>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
                </Breadcrumb>
                <hr />
                <Row>
                    <Col>
                    <h2>Contact Us</h2>
                    <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                    <h5>General Information: </h5>
                    <hr />
                    Email: <Mailto email="info@jphb.us" subject="" body=""><span style={{color: 'blue'}}>info@jphb.us</span></Mailto><br /><br />
                    Phone: <a href="tel:970-215-4116"><span style={{color: 'blue'}}>+1 (970) 215-4116</span></a><br /><br /><br />
                    <h5>Investor Information: </h5>
                    <hr />
                    Email: <Mailto email="amit@jphb.us" subject="" body=""><span style={{color: 'blue'}}>amit@jphb.us</span></Mailto><br /><br />
                    Phone: <a href="tel:970-215-4116"><span style={{color: 'blue'}}>+1 (970) 215-4116</span></a><br /><br />
                    </Col>
                    <br />
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                    <h5>Mailling Address</h5>
                    <hr />
                    1904 Orchard Place<br />
                    Fort Collins, Colorado <br />
                    80521 <br /><br /><br />
                    <h5>Web Contact: </h5>
                    <hr />
                    Email: <Mailto email="willnichols@jphb.us" subject="" body=""><span style={{color: 'blue'}}>willnichols@jphb.us</span></Mailto><br /><br />
                    </Col>
                </Row>
            </Container>

        </React.Fragment>
    );
}

export default Contact;
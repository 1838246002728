import React from 'react';
import "./css/Navajo.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap';

import NavajoImg1 from './assets/Navajo1.jpg';
import NavajoImg2 from './assets/Navajo2.jpg';
import NavajoImg3 from './assets/Navajo3.jpg';
import NavajoImg4 from './assets/Navajo4.jpg';
import NavajoImg5 from './assets/Navajo5.jpg';
import NavajoImg6 from './assets/Navajo6.jpg';

const Mailto = ({ email, subject, body, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
    );
  };

class Navajo extends React.Component {
    render() {
        window.scrollTo(0,0);
        return (
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Navajo</Breadcrumb.Item>
                </Breadcrumb>
                <hr />
                <Row>
                    <Col>
                        <div class="about-image-block-container right-overlap">
                            <img src={NavajoImg1} className="about-content-img" alt="about_us" />
                            <div className="about-outer">
                                <div class="about-text-block">
                                    <h2>Navajo Reservation Projects</h2>
                                    <hr className="visionHr" /><br />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <img src={NavajoImg3} className="blog-image-left" alt="navajo" />
                            In the past one year, we visited 3 sites in 2 reservations (Navajo Mountain Reservation and Alamo Reservation). We have often seen solutions that have been provided to these families as not being the most suitable. That is mostly since many of these solutions are applied without an intimate communication with the potential recipients of these solutions. The intent is respectable but for a solution to be impactful, it is important the true nature and extent of the problem is identified and understood.
                            <br /><br />
                            During our visit, we learned how difficult it is to even reach some of these families. Some of them are so remotely located that the only way to reach them is using an off-road vehicle driving over dry riverbeds and being hopeful that it does not begin to rain. Some locations were difficult to reach even with off-road vehicles due to the terrain and complete absence of roads.
                            <br /><br />
                            In some of the locations we visited, the nearest hospitals or medical facilities are tens of mile away and location inaccessible by any emergency vehicle such as an ambulance in case of an emergency. The nearest electrical grid lines too are several miles away making it very expensive for utility companies to provide reliable electricity. Furthermore, there is little to no scope of employment locally.
                            <br /><br />
                            Our goal is to make resilient off-grid energy available to these families as the first step towards providing them with means to improve their living standards. Our current efforts are aimed at meeting these families, talking to them and identifying their true needs and addressing them without using a one-size fits all approach. Access to energy will also enable better education for the younger members of the society as well as provide means for a healthier lifestyle.
                        </div>
                        <br /><br />
                        <div>
                            <img src={NavajoImg4} className="blog-image-right" alt="navajo" />
                            This can best be done by using off-grid solar energy installations for individual families. While such efforts have been made in the past, they have not been very helpful since a few years after the installation the systems saw various failures. There were no efforts planned or made to repair these broken systems and hence the families were again left with no energy. We, therefore, are working towards an approach where we do not install some off-grid solar and walk away but instead create an inclusive solution network that is built around solar energy.
                            <br /><br />
                            While the first step is to assess the sites and the needs which allow us to identify appropriate solutions, that is only a start. Using these assessment reports, we intended to approach various institutions to avail resources – funds and in-kind – to support these families. Following the initial installation to meet minimal energy needs, we aim to expand the efforts towards creating an infrastructure that promotes a self-sustained ecosystem for employment, socio-economical development, education, health care, etc.
                        </div>
                        <br />
                        <div>
                            Our experience talking to these families gave us a glimpse into their lives. They were truly happy that there was a group of people that did not go to them with a solution. Instead, a group is willing to listen to their problems and understand their needs before identifying a solution.
                            <br /><br />
                            If you would like to learn more, please reach out to –<br /><br />
                            Dr. Anthony Nicholson or Dr. Amit Munshi<br />
                            <Mailto email="info@jphb.us" subject="" body=""><span style={{color: 'blue'}}>info@jphb.us</span></Mailto><br /><br /><br />
                        </div>
                        <div>
                            <img src={NavajoImg5} className="blog-image-left-2" alt="navajo" />
                            <img src={NavajoImg6} className="blog-image-right-2" alt="navajo" />
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Navajo;